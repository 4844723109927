<template>
  <b-tab 
    title="Register Now" 
    title-item-class="payment-portal__tab"
  >
    <app-collapse class="mb-3">
      <app-collapse-item
        title="Payment Contracts"
        :is-visible="true"
      >
      <div class="overflow-auto">
        <b-table
          ref="refPaymentContractsTable"
          :items="paymentContractsUpcomingTransactions"
          :fields="paymentContractsTableColumns"
          :busy="isBusy"
          thead-class="table-header"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          class="position-relative w-100"
        >

          <!-- Loader -->
          <template #table-busy>
            <div class="d-flex justify-content-center">
              <b-spinner 
                class="align-middle"
              />
            </div>
          </template>

          <!-- Column: Id -->
          <template #cell(id)="data">
            <span :id="data.item.id">{{ data.item.id }}</span>
          </template>

          <!-- Column: amount -->
          <template #cell(amount)="data">
            {{ data.value | fixAmount }}
          </template>

          <!-- Column: Date -->
          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | longDate }}</span>
          </template>
        </b-table>
      </div>
        
      </app-collapse-item>
    </app-collapse>
    
    <template 
      v-for="(paymentPlanTable, key, index) in paymentPlanUpcomingTransactionsTables"
    >
      <app-collapse
        :key="index"
        class="mb-3"
      >
        <app-collapse-item
          :title="`Payment Plan ${index+1}`"
          :is-visible="true"
        >
          <b-table
            ref="refPaymentPlanTable"
            :items="paymentPlanTable"
            :fields="paymentPlanTableColumns"
            :busy="isBusy"
            thead-class="table-header"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            class="position-relative w-100"
          >

            <!-- Loader -->
            <template #table-busy>
              <div class="d-flex justify-content-center">
                <b-spinner 
                  class="align-middle"
                />
              </div>
            </template>

            <!-- Column: Id -->
            <template #cell(id)="data">
              <span :id="data.item.id">{{ data.item.id | shortId }}</span>
              <b-tooltip
                triggers="hover"
                :target="data.item.id"
                :delay="{ show: 100, hide: 50 }"
              >
                {{ data.item.id }}
              </b-tooltip>
            </template>

            <!-- Column: Date -->
            <template #cell(created_at)="data">
              <span>{{ data.item.created_at | longDate }}</span>
            </template>

            <!-- Column: amount -->
            <template #cell(amount)="data">
              {{ data.value | fixAmount }}
            </template>
            
            <!-- Column: credit_card -->
            <template #cell(credit_card)="data">
              <span v-if="data.item.card">{{ data.item.card.type }} Xxxx {{ data.item.card.last_digits }}</span>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">

              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="mx-auto p-0"
                no-caret
              >

                <template #button-content>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00033 7.58366C7.32249 7.58366 7.58366 7.32249 7.58366 7.00033C7.58366 6.67816 7.32249 6.41699 7.00033 6.41699C6.67816 6.41699 6.41699 6.67816 6.41699 7.00033C6.41699 7.32249 6.67816 7.58366 7.00033 7.58366Z" stroke="#777879" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.0833 7.58366C11.4055 7.58366 11.6667 7.32249 11.6667 7.00033C11.6667 6.67816 11.4055 6.41699 11.0833 6.41699C10.7612 6.41699 10.5 6.67816 10.5 7.00033C10.5 7.32249 10.7612 7.58366 11.0833 7.58366Z" stroke="#777879" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.91634 7.58366C3.23851 7.58366 3.49967 7.32249 3.49967 7.00033C3.49967 6.67816 3.23851 6.41699 2.91634 6.41699C2.59417 6.41699 2.33301 6.67816 2.33301 7.00033C2.33301 7.32249 2.59417 7.58366 2.91634 7.58366Z" stroke="#777879" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </template>

                <b-dropdown-item
                  class="payment-portal__actions-menuitem"
                  no-caret
                  v-b-modal.change-payment-method
                >
                  <span>Change Payment detail</span>
                </b-dropdown-item>

                <b-dropdown-item
                  class="payment-portal__actions-menuitem"
                  no-caret
                  @click="makeSpecificPayment(data.item, paymentPlanTable.length)"
                >
                  <span>Make Payment</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </app-collapse-item>
      </app-collapse>
    </template>
    
    <MakeSpecificPayment
      v-if="payment"
    />
  </b-tab>
</template>

<script>
import { mapGetters } from 'vuex'
import store from "@/store"

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { BTab, BTable, BTooltip, BSpinner, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import MakeSpecificPayment from "@/views/PaymentPortal/Modals/Payment/MakeSpecificPayment.vue"
import { filters } from '@core/mixins/filters'
import { instanceSessionCamperNames } from '@core/mixins/instanceSessionCamperNames'

export default {
  name: "UpcomingPaymentsTab",
  components: {
    AppCollapse,
    AppCollapseItem,
    BTab,
    BTable,
    BTooltip,
    BSpinner,
    BDropdown,
    BDropdownItem,

    MakeSpecificPayment,
  },
  mixins: [ filters, instanceSessionCamperNames ],
  computed: {
    ...mapGetters({
      paymentContractsUpcomingTransactions: 'paymentPortal/getPaymentContractsUpcomingTransactions',
      paymentPlanUpcomingTransactionsTables: 'paymentPortal/getPaymentPlanUpcomingTransactionsTables',
      payment: 'paymentPortal/getSelectedUpcomingPayment',
      isBusy: 'paymentPortal/getIsUpcomingPaymentsBusy',
    }),
    paymentContractsTableColumns() {
      return [
        { key: 'id', label: 'Id' },
        { key: 'student_name', label: this.camperStudent },
        { key: 'program_name', label: 'Program Name' },
        { key: 'amount', label: 'Amount' },
        { key: 'pay_date', label: 'Payment Date' },
        { key: 'payment_method', label: 'Payment Method' },
      ]
    },
    paymentPlanTableColumns() {
      return [
        { key: 'pay_date', label: 'Date' },
        { key: 'student_name', label: this.camperStudent },
        { key: 'program_name', label: 'Program Name' },
        { key: 'amount', label: 'Amount' },
        { key: 'payment_method', label: 'Type' },
        { key: 'credit_card', label: 'Credit Card' },
        { key: 'actions', label: 'Actions', class: 'table-actions' },
      ]
    },
  },
  async created() {
    await store.dispatch('paymentPortal/fetchUpcomingPayments')
  },
  methods: {
    makeSpecificPayment(payment, amountOfPayments) {
      store.commit('paymentPortal/SET_SELECTED_UPCOMING_PAYMENT', payment)
      store.commit('paymentPortal/SET_AMOUNT_OF_UPCOMING_PAYMENTS', amountOfPayments)
      
      this.$nextTick(() => {
        this.$bvModal.show('make-specific-payment')
      })
    },
  },
}
</script>

<style lang="scss">
.table-actions {
  width: 50px;
  text-align: center;
}
</style>