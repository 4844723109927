<template>
  <div class="payment-info">
    <h5 class="payment-info__title">Payment Information</h5>

    <div
      v-if="application"
      class="payment-info__block"
    >
      <h6>Application selection</h6>
      <p>
        <span>{{ application.user.full_name }}</span>&nbsp;-&nbsp;<span>{{ application.program }}</span>
      </p>
    </div>

    <div
      v-if="charges.length > 0"
      class="payment-info__block"
    >
      <h6>Charges information</h6>
      <p v-for="charge in charges" :key="charge.id">
        <span v-if="charge.description">{{ charge.description }}</span> <span v-else>null</span>
        <span>({{ charge.unpaid_sum | fixAmount }})</span>
      </p>
    </div>

    <div class="payment-info__block">
      <h6>Payment Method</h6>
      <p>{{ method }}</p>
    </div>

    <div
      v-if="method =='Credit Card'"
      class="payment-info__block"
    >
      <h6>Payment Option</h6>
      <p>
        <VisaSVG v-if="card.type == 'Visa'" />
        <MastercardSVG v-if="card.type == 'MasterCard'" />
        xxxx {{ card.last_digits }}
      </p>
    </div>

    <div
      v-if="includeCashBank"
      class="payment-info__block"
    >
      <h6>Included CashBank in payment</h6>
      <p>
        {{ cashBankBalance | fixAmount }}
      </p>
    </div>

    <div
      class="d-flex justify-content-between bg-light p-3 rounded-lg"
    >
      <span>Total:</span> <span class="font-weight-bold">{{ total }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { filters } from '@core/mixins/filters'
import VisaSVG from '@/assets/images/icons/visa.svg'
import MastercardSVG from '@/assets/images/icons/mastercard.svg'

export default {
  components: {
    VisaSVG,
    MastercardSVG,
  },
  mixins: [ filters ],
  computed: {
    ...mapGetters({
      charges: 'paymentPortal/getChargesList',
      card: 'paymentPortal/getSelectedCardInfo',
      application: 'paymentPortal/getSelectedApplication',
      method: 'paymentPortal/getPaymentMethod',
      total: 'paymentPortal/getPaymentAmount',
      cashBankBalance: 'paymentPortal/getCashBankBalance',
      includeCashBank: 'paymentPortal/getIncludeCashBank',
    }),
  },
}
</script>

<style>

</style>