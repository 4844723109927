import store from '@/store'

export const setStatusModalData = {
  methods: {
    setStatusModalData(data) {
      store.commit('paymentPortal/SET_STATUS_MODAL_TITLE', data.title)
      store.commit('paymentPortal/SET_STATUS_MODAL_STATUS', data.status)
      store.commit('paymentPortal/SET_STATUS_MODAL_HEADER', data.header)
      store.commit('paymentPortal/SET_STATUS_MODAL_MESSAGE', data.message)
    }
  },
}

export const _ = null
