<template>
  <b-form-radio
    v-model="selectedCard"
    :value="card.id"
    class="bordered-radio mb-3"
    name="credit-card"
    stacked
  >
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <VisaSVG v-if="card.type == 'Visa'" />
        <MastercardSVG v-if="card.type == 'MasterCard'" />
        <span class="ml-2">xxxx {{ card.last_digits }}</span>
      </div>
      <img
        v-if="cardsList.length > 1 && showDeleteCard"
        src="@/assets/images/icons/trash.svg" 
        alt=""
        class="cursor-pointer"
        @click="removeCreditCard"
      />
    </div>
  </b-form-radio>
</template>

<script>
import store from "@/store"
import { BFormRadio } from 'bootstrap-vue'
import VisaSVG from '@/assets/images/icons/visa.svg'
import MastercardSVG from '@/assets/images/icons/mastercard.svg'

export default {
  components: {
    BFormRadio,

    VisaSVG,
    MastercardSVG,
  },
  props: {
    cardsList: {
      type: Array,
      default: () => [],
    },
    card: {
      type: Object,
      default: () => {},
    },
    showDeleteCard: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    selectedCard: {
      get: function () {
        return store.getters['paymentPortal/getSelectedCreditCard']
      },
      set: function (val) {
        store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', val)
      }
    },
  },
  methods: {
    async removeCreditCard() {
      if (this.card.card_is_use) {
        store.commit('paymentPortal/SET_CARD_FOR_REMOVE', this.card)
        this.$bvModal.show('replace-card')
      } else {
        store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
        await store.dispatch('paymentPortal/deleteCreditCard', this.card.id)
        await store.dispatch('paymentPortal/fetchCreditCardsList')
        store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', '')
        store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
      }
    },
  }
}
</script>

<style>

</style>