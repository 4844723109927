<template>
  <b-modal
    id="make-specific-payment"
    ref="make-specific-payment"
    centered
    title="Make a payment"
    @show="setModalData"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-row
        v-if="!showPaymentDetails"
        class="p-2"
      >
        
        <b-col
          cols="12"
          class="mb-3"
        >
          <label
            class="form__row"
          >
            <span class="form__row-label">Amount $</span>
            <input
              v-model.number="paymentAmount"
              :disabled="amountOfUpcomingPayments == 1"
              type="number"
              class="input"
            />
          </label>
          <p
            v-if="isAmountMoreThenTotal"
            class="text-primary"
          >
            *The amount entered is more than the payment amount
          </p>
        </b-col>
        
        <b-col
          cols="12"
          class="mb-3"
        >
          <b-form-group
            label="Select Payment Method"
          >
            <v-select
              v-model="paymentMethod"
              :options="paymentMethodsList"
              class="customSelect"
              placeholder="Please Select Payment Method"
              :clearable="false"
              :searchable="false"
              :selectable="isOptionSelectable"
            >
              <template
                #selected-option="{ label }"
              >
                <div
                  v-if="label == 'Cash Bank'"
                >
                  <span>{{ label }}</span> <span>({{ cashBankBalance | fixAmount }})</span>
                </div>

                <div v-else>
                  {{ label }}
                </div>
              </template>

              <template
                #option="{ label }"
              >
                <div
                  v-if="label == 'Cash Bank'"
                  class="d-flex justify-content-between"
                >
                  <span>{{ label }}</span><span>{{ cashBankBalance | fixAmount }}</span>
                </div>
                <div v-else>
                  {{ label }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          v-if="paymentMethod == 'Credit Card'"
          cols="12"
          class="mb-1"
        >
          <b-form-group label="Select Credit Card">
            <CreditCardRadioBlock
              v-for="card in cardsList"
              :key="card.id"
              :cards-list="cardsList"
              :card="card"
            />
            <b-button
              variant="primary"
              class="w-100 mt-2"
              v-b-modal.add-new-card
            >
              Add New Credit Card +
            </b-button>
          </b-form-group>
        </b-col>

        <b-col
          v-if="paymentMethod !== 'CashBank' && cashBankBalance && cashBankBalance < paymentAmount"
          cols="12"
        >
          <b-form-checkbox
            v-model="includeCashBank"
            class="mr-1 bulk-checkbox"
          >
           Include CashBank amount ({{ cashBankBalance | fixAmount }}) in payment
          </b-form-checkbox>
        </b-col>

        <b-col
          v-if="errorMessage"
          cols="12"
          class="mb-1"
        >
          <p
            class="text-danger text-center"
          >
            {{ errorMessage }}
          </p>
        </b-col>
      </b-row>

      <PaymentInformation
        v-if="showPaymentDetails"
      />
    </b-overlay>
    <template #modal-footer="{ cancel }">
      <div
        v-if="!showPaymentDetails"
        class="w-100 d-flex justify-content-between"
      >
        <b-button
          class="border border-primary"
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="submitButtonIsLoading || !isMakePaymentEnabled"
          @click="showPaymentDetails = true"
        >
          <span>Make Payment</span>
        </b-button>
      </div>
      <div
        v-else
        class="w-100 d-flex justify-content-between"
      >
        <b-button
          class="border border-primary"
          variant="outline-primary"
          @click="showPaymentDetails = false"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!isMakePaymentEnabled"
          @click="handleForm"
        >
          <span>Submit Payment</span>
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import { filters } from '@core/mixins/filters'

import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  VBModal,
  BButton,
  BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import PaymentInformation from '@/views/PaymentPortal/Modals/Payment/PaymentInformation.vue'
import CreditCardRadioBlock from '@/components/site/CreditCardRadioBlock.vue'
import { setStatusModalData } from '@core/mixins/setStatusModalData'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BOverlay,
    BFormCheckbox,

    vSelect,

    PaymentInformation,
    CreditCardRadioBlock,
  },
  mixins: [ filters, setStatusModalData ],
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      userId: getUserData().id,
      errorMessage: '',
      submitButtonIsLoading: false,
      showPaymentDetails: false,
    }
  },
  computed: {
    ...mapGetters({
      upcomingPayment: 'paymentPortal/getSelectedUpcomingPayment',
      paymentMethodsList: 'paymentPortal/getPaymentMethodsList',
      cardsList: 'paymentPortal/getCreditCardsList',
      selectedCard: 'paymentPortal/getSelectedCreditCard',
      selectedCardInfo: 'paymentPortal/getSelectedCardInfo',
      cardForRemove: 'paymentPortal/getCardForRemove',
      isLoading: 'paymentPortal/getIsModalLoaidng',
      cashBankBalance: 'paymentPortal/getCashBankBalance',
      amountOfUpcomingPayments: 'paymentPortal/getAmountOfUpcomingPayments',
    }),
    paymentAmount: {
      get: function () {
        return store.getters['paymentPortal/getPaymentAmount']
      },
      set: function (val) {
        store.commit('paymentPortal/SET_PAYMENT_AMOUNT', val)
      }
    },
    paymentMethod: {
      get: function () {
        return store.getters['paymentPortal/getPaymentMethod']
      },
      set: function (val) {
        store.commit('paymentPortal/SET_PAYMENT_METHOD', val)
      }
    },
    includeCashBank: {
      get: function () {
        return store.getters['paymentPortal/getIncludeCashBank']
      },
      set: function (val) {
        store.commit('paymentPortal/SET_INCLUDE_CASH_BANK_STATUS', val)
      }
    },
    isMakePaymentEnabled() {
      if (this.paymentAmount && this.paymentMethod && !this.isAmountMoreThenTotal) {
        if (this.paymentMethod === 'Credit Card' && !this.selectedCard) {
          return false
        }
        return true
      }
      return false
    },
    totalPaymentAmount() {
      return this.upcomingPayment.amount
    },
    isAmountMoreThenTotal() {
      return this.paymentAmount > this.totalPaymentAmount
    },
  },
  methods: {
    async setModalData() {
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      await store.dispatch('paymentPortal/fetchCreditCardsList')
      store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', this.upcomingPayment.card.id)
      store.commit('paymentPortal/SET_PAYMENT_METHOD', this.upcomingPayment.payment_method)
      store.commit('paymentPortal/SET_PAYMENT_AMOUNT', this.upcomingPayment.amount.toFixed(2))
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
    },
    resetModal() {
      store.commit('paymentPortal/SET_PAYMENT_METHOD', 'Credit Card')
      store.commit('paymentPortal/SET_PAYMENT_AMOUNT', 0)
      store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', '')
      store.commit('paymentPortal/SET_INCLUDE_CASH_BANK_STATUS', false)
      this.showPaymentDetails = false
    },
    selectCard(cardId) {
      store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', cardId)
    },
    async handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      if(this.includeCashBank) {
        this.makePaymentIncludeCashBank()
      } else {
        this.makePayment()
      }

    },
    async makePayment() {
      this.submitButtonIsLoading = true
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)

      const payload = {
        id: this.upcomingPayment.id,
        params: {
          amount: this.paymentAmount,
          payment_method: this.paymentMethod,
        }
      }

      if (this.paymentMethod === 'Credit Card') {
        payload.params.card_id = this.selectedCard
      }

      await store.dispatch('paymentPortal/payUpcomingPaymentTransaction', payload)
        .then(() => {
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
          this.submitButtonIsLoading = false

          const data = {
            title: 'Make payment',
            status: 'Success',
            header: 'Your payment has been sent',
            message: '',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('make-specific-payment')
          this.$bvModal.show('status-modal')

          store.dispatch('paymentPortal/fetchCashBankBalance')
          store.dispatch('paymentPortal/fetchTransactionsList')
          store.dispatch('paymentPortal/fetchUpcomingPayments')
        })
        .catch(() => {
          this.submitButtonIsLoading = false
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

          const data = {
            title: 'Make a payment',
            status: 'Failed',
            header: 'Your payment failed',
            message: 'You need to check if there is money on the card (or contact the bank about the error)',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('make-specific-payment')
          this.$bvModal.show('status-modal')
        })
    },
    async makePaymentIncludeCashBank() {
      this.submitButtonIsLoading = true
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)

      const payload = {
        id: this.upcomingPayment.id,
        params: { 
          amount: this.paymentAmount - this.cashBankBalance,
          payment_method: this.paymentMethod,
        }
      }

      if (this.paymentMethod === 'Credit Card') {
        payload.params.card_id = this.selectedCard
      }

      const cashBankPaymentQueryParams = {
        user_id: this.userId,
        application_id: this.upcomingPayment.application_pay_condition.application_id,
        amount: this.cashBankBalance,
        type: 'Cash Bank',
      }
      try {
        await store.dispatch('paymentPortal/payUpcomingPaymentTransaction', payload)
          .then(() => {
            store.dispatch('paymentPortal/sendNewTransaction', cashBankPaymentQueryParams)
          })
        store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
        this.submitButtonIsLoading = false

        const data = {
          title: 'Make payment',
          status: 'Success',
          header: 'Your payment has been sent',
          message: '',
        }

        this.setStatusModalData(data)
        this.$bvModal.hide('make-specific-payment')
        this.$bvModal.show('status-modal')

        store.dispatch('paymentPortal/fetchTransactionsList')
        store.dispatch('paymentPortal/fetchUpcomingPayments')
        store.dispatch('paymentPortal/fetchCashBankBalance')
      } catch (error) {
        this.submitButtonIsLoading = false
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

          const data = {
            title: 'Make payment',
            status: 'Failed',
            header: 'Your payment failed',
            message: '',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('make-specific-payment')
          this.$bvModal.show('status-modal')
      }
    },
    isOptionSelectable(option){
      return option == 'Credit Card' || this.paymentAmount <= this.cashBankBalance
    }
  },
};
</script>