<template>
  <b-modal
    id="cash-bank"
    ref="cash-bank"
    size="lg"
    centered
    title="CashBank"
    ok-title="Close"
    ok-only
    @show="setModalData"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <div class="cash-bank__info">
        <h5 class="cash-bank__title">Current CashBank balance</h5>
        <p class="cash-bank__value">{{ cashBankBalance | fixAmount }}</p>
      </div>

      <h5 class="text-secondary mb-3">CashBank history</h5>

    
      <b-table
        ref="refCashBankTable"
        :items="fetchCashBankTransactions"
        :fields="tableColumns"
        primary-key="id"
        thead-class="table-header"
        show-empty
        responsive
        :busy="isTableBusy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Loader -->
        <template #table-busy>
          <div class="d-flex justify-content-center">
            <b-spinner 
              class="align-middle"
            />
          </div>
        </template>

        <!-- Column: Date -->
        <template #cell(created_at)="data">
          <span>{{ data.item.created_at | shortDate }}</span>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <div class="d-flex align-items-center">
            <img
              v-if="data.item.status == 'Paid'"
              src="@/assets/images/icons/check.svg" alt=""
            >
            <img
              v-if="data.item.status == 'Added By User'"
              src="@/assets/images/icons/user-plus.svg" alt=""
            >
            <img
              v-if="data.item.status == 'Refunded'"
              src="@/assets/images/icons/corner-up-left.svg" alt=""
            >
            <span class="ml-2">{{ data.item.status }}</span>
          </div>
        </template>

        <!-- Column: Amount -->
        <template #cell(amount)="data">
          <div class="text-right font-weight-bold">{{ data.item.amount | fixAmount }}</div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            <span
              v-b-modal.add-cash
              class="cash-bank__add-cash"
            >Add Cash</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              pills
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            />

          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
// import store from '@/store'

import { 
  BModal, BTable, BRow, BCol,
  BPagination, BSpinner, BOverlay,
} from 'bootstrap-vue'

import useCashBank from '@/views/PaymentPortal/Modals/CashBank/useCashBank'
import { filters } from '@core/mixins/filters'

export default {
  components: {
    BModal,
    BTable,
    BRow,
    BCol,
    BPagination,
    BSpinner,
    BOverlay,
  },
  mixins: [ filters ],
  data() {
    return {
      isTableBusy: false,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'paymentPortal/getIsModalLoaidng',
      cashBankBalance: 'paymentPortal/getCashBankBalance',
    }),
  },
  watch: {
    cashBankBalance() {
      if(this.$refs.refCashBankTable){
        this.refetchData()
      }
    },
  },
  setup() {
    const {
      fetchCashBankTransactions,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      sortBy,
      totalItems,
      isSortDirDesc,
      refCashBankTable,

      refetchData,
    } = useCashBank()

    return {
      fetchCashBankTransactions,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      sortBy,
      totalItems,
      isSortDirDesc,
      refCashBankTable,

      refetchData,
    }
  },
  methods: {
    async setModalData() {

    }
  }
}
</script>

<style>

</style>