<template>
  <b-modal
    id="change-default-card"
    ref="change-default-card"
    body-class="p-10"
    centered
    title="Change Default Card"
    @show="setModalData"
    @hidden="resetModal"
    @ok="changeDefaultCard"
  >
    <b-overlay
      id="overlay-new-card"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-form-group label="Application selection">
        <v-select
          v-model="selectedApplication"
          :options="applicationsList"
          class="customSelect"
          placeholder="Please Select Application"
          :clearable="false"
          @input="selectApp"
        >
          <template #selected-option="{ program, user }">
            <span>{{ user.full_name }}</span
            >&nbsp;-&nbsp;<span>{{ program }}</span>
          </template>

          <template #option="{ program, user }">
            <span>{{ user.full_name }}</span> - <span>{{ program }}</span>
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Credit Card">
        <CreditCardRadioBlock
          v-for="card in cardsList"
          :key="card.id"
          :cards-list="cardsList"
          :card="card"
        />
        <b-button variant="primary" class="w-100 mt-2" v-b-modal.add-new-card>
          Add New Credit Card +
        </b-button>
      </b-form-group>
    </b-overlay>
    <template #modal-footer="{ cancel, ok }">
      <div class="w-100 d-flex justify-content-between">
        <b-button
          class="border border-primary"
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="submitButtonIsLoading || isOkButtonDisabled"
          @click="ok()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import { BModal, BButton, BOverlay, BFormGroup } from "bootstrap-vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import CreditCardRadioBlock from "@/components/site/CreditCardRadioBlock.vue";
import { setStatusModalData } from "@core/mixins/setStatusModalData";

export default {
  name: "ChangeDefaultCard",
  components: {
    BModal,
    BButton,
    BOverlay,
    BFormGroup,
    vSelect,

    CreditCardRadioBlock,
  },
  mixins: [setStatusModalData],
  data() {
    return {
      submitButtonIsLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      applicationsList: "paymentPortal/getApplications",
      cardsList: "paymentPortal/getCreditCardsList",
      selectedCard: "paymentPortal/getSelectedCreditCard",
      isLoading: "paymentPortal/getIsModalLoaidng",
    }),
    selectedApplication: {
      get: function () {
        return store.getters["paymentPortal/getSelectedApplication"];
      },
      set: function (val) {
        store.commit("paymentPortal/SET_SLECTED_APPLICATION", val);
      },
    },
    isOkButtonDisabled() {
      return !this.selectedApplication || !this.selectedCard;
    },
  },
  methods: {
    async setModalData() {
      store.commit("paymentPortal/SET_MODAL_LOADING_STATE", true);
      await store.dispatch("paymentPortal/fetchCreditCardsList");
      await store.dispatch("paymentPortal/fetchApplications");
      store.commit("paymentPortal/SET_MODAL_LOADING_STATE", false);
    },
    resetModal() {
      store.commit("paymentPortal/SET_SLECTED_APPLICATION", null);
      store.commit("paymentPortal/SET_SELECTED_CREDIT_CARD", "");
    },
    selectApp(application) {
      store.commit(
        "paymentPortal/SET_SELECTED_CREDIT_CARD",
        application.card_id
      );
    },
    async changeDefaultCard(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.submitButtonIsLoading = true;
      store.commit("paymentPortal/SET_MODAL_LOADING_STATE", true);

      const queryParams = {
        application_id: this.selectedApplication?.id,
        card_id: this.selectedCard,
      };

      await store
        .dispatch("paymentPortal/changeDefaultCard", queryParams)
        .then(() => {
          this.submitButtonIsLoading = false;
          store.commit("paymentPortal/SET_MODAL_LOADING_STATE", false);

          const data = {
            title: "Change Default Card",
            status: "Success",
            header: "Default Card for this application was changed",
            message: "",
          };

          this.setStatusModalData(data);
          this.$bvModal.hide("change-default-card");
          this.$bvModal.show("status-modal");

          store.dispatch("paymentPortal/fetchTransactionsList");
          store.dispatch("paymentPortal/fetchUpcomingPayments");
          store.dispatch("paymentPortal/fetchCashBankBalance");
        })
        .catch(() => {
          this.submitButtonIsLoading = false;
          store.commit("paymentPortal/SET_MODAL_LOADING_STATE", false);

          const data = {
            title: "Change Default Card",
            status: "Failed",
            header: "Error changing default Card",
            message: "",
          };

          this.setStatusModalData(data);
          this.$bvModal.hide("change-default-card");
          this.$bvModal.show("status-modal");
        });
    },
  },
};
</script>

<style>
</style>