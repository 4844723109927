<template>
  <b-modal
    id="make-payment"
    ref="make-payment"
    centered
    title="Make a payment"
    @show="setModalData"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-row
        v-if="!showPaymentDetails"
        class="p-2"
      >
        <b-col
          cols="12"
          class="mb-3"
        >
          <b-form-group
            label="Application selection"
          >
            <v-select
              v-model="selectedApplication"
              :options="applications"
              class="customSelect"
              placeholder="Please Select Application"
              :clearable="false"
              @input="selectApp"
            >
              <template
                #selected-option="{ program, user }"
              >
                <span>{{ user.full_name }}</span>&nbsp;-&nbsp;<span>{{ program }}</span>
              </template>

              <template
                #option="{ program, user }"
              >
                <span>{{ user.full_name }}</span> - <span>{{ program }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          v-if="chargesList.length > 0"
          cols="12"
          class="mb-3"
        >
          <div
            class="d-flex justify-content-between bg-light py-1 px-3 mb-2 rounded-lg"
          >
            <span>Charges information</span><span>Amount</span>
          </div>
          <div
            v-for="charge in chargesList"
            :key="charge.id"
          >
              <div
                class="d-flex justify-content-between mb-1 w-100 charge px-2"
              >
                <span v-if="charge.description">{{ charge.description }}</span> <span v-else>null</span>
                <span>{{ charge.unpaid_sum | fixAmount }}</span>
              </div>
              <hr>
          </div>
        </b-col>
        
        <b-col
          cols="12"
          class="mb-3"
        >
          <label
            class="form__row"
          >
            <span class="form__row-label">Total $</span>
            <input
              v-model.number="paymentAmount"
              type="number"
              class="input"
            />
          </label>
          <p
            v-if="isAmountMoreThenTotal"
            class="text-primary"
          >
            *The amount entered is more than application total amount
          </p>
          
        </b-col>
        
        <b-col
          cols="12"
          class="mb-3"
        >
          <b-form-group
            label="Select Payment Method"
          >
            <v-select
              v-model="paymentMethod"
              :options="paymentMethodsList"
              class="customSelect"
              placeholder="Please Select Payment Method"
              :clearable="false"
              :searchable="false"
              :selectable="isOptionSelectable"
            >
              <template
                #selected-option="{ label }"
              >
                <div
                  v-if="label == 'Cash Bank'"
                >
                  <span>{{ label }}</span> <span>({{ cashBankBalance | fixAmount }})</span>
                </div>

                <div v-else>
                  {{ label }}
                </div>
              </template>

              <template
                #option="{ label }"
              >
                <div
                  v-if="label == 'Cash Bank'"
                  class="d-flex justify-content-between"
                >
                  <span>{{ label }}</span><span>{{ cashBankBalance | fixAmount }}</span>
                </div>
                <div v-else>
                  {{ label }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          v-if="paymentMethod == 'Credit Card'"
          cols="12"
          class="mb-1"
        >
          <b-form-group label="Select Credit Card">
            <CreditCardRadioBlock 
              v-for="card in cardsList"
              :key="card.id"
              :cards-list="cardsList"
              :card="card"
            />
            <b-button
              variant="primary"
              class="w-100 mt-2"
              v-b-modal.add-new-card
            >
              Add New Credit Card +
            </b-button>
          </b-form-group>
        </b-col>

        <b-col
          v-if="paymentMethod !== 'Cash Bank' && cashBankBalance && cashBankBalance < paymentAmount"
          cols="12"
        >
          <b-form-checkbox
            v-model="includeCashBank"
            class="mr-1 bulk-checkbox"
          >
           Include CashBank amount ({{ cashBankBalance | fixAmount}}) in payment
          </b-form-checkbox>
        </b-col>

        <b-col
          v-if="errorMessage"
          cols="12"
          class="mb-1"
        >
          <p
            class="text-danger text-center"
          >
            {{ errorMessage }}
          </p>
        </b-col>
      </b-row>

      <PaymentInformation
        v-if="showPaymentDetails"
      />
    </b-overlay>
    <template #modal-footer="{ cancel }">
      <div
        v-if="!showPaymentDetails"
        class="w-100 d-flex justify-content-between"
      >
        <b-button
          class="border border-primary"
          variant="outline-primary" 
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="submitButtonIsLoading || !isMakePaymentEnabled"
          @click="showPaymentDetails = true"
        >
          <span>Make Payment</span>
        </b-button>
      </div>
      <div
        v-else
        class="w-100 d-flex justify-content-between"
      >
        <b-button
          class="border border-primary"
          variant="outline-primary" 
          @click="showPaymentDetails = false"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="submitButtonIsLoading || !isMakePaymentEnabled"
          @click="handleForm"
        >
          <span>Submit Payment</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import {
  BModal, BRow, BCol, BFormGroup, 
  VBModal, BButton, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import PaymentInformation from '@/views/PaymentPortal/Modals/Payment/PaymentInformation.vue'
import CreditCardRadioBlock from '@/components/site/CreditCardRadioBlock.vue'

import { filters } from '@core/mixins/filters'
import { setStatusModalData } from '@core/mixins/setStatusModalData'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BOverlay,
    BFormCheckbox,

    vSelect,

    PaymentInformation,
    CreditCardRadioBlock,
  },
  mixins: [ filters, setStatusModalData ],
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      userId: getUserData().id,
      errorMessage: '',
      submitButtonIsLoading: false,
      showPaymentDetails: false,
    }
  },
  computed: {
    ...mapGetters({
      applications: 'paymentPortal/getApplications',
      chargesList: 'paymentPortal/getChargesList',
      paymentMethodsList: 'paymentPortal/getPaymentMethodsList',
      cardsList: 'paymentPortal/getCreditCardsList',
      selectedCard: 'paymentPortal/getSelectedCreditCard',
      selectedCardInfo: 'paymentPortal/getSelectedCardInfo',
      cardForRemove: 'paymentPortal/getCardForRemove',
      isLoading: 'paymentPortal/getIsModalLoaidng',
      cashBankBalance: 'paymentPortal/getCashBankBalance',
    }),
    selectedApplication: {
      get: function () {
        return store.getters['paymentPortal/getSelectedApplication']
      },
      set: function (val) {
        store.commit('paymentPortal/SET_SLECTED_APPLICATION', val)
      }
    },
    paymentAmount: {
      get: function () {
        return store.getters['paymentPortal/getPaymentAmount']
      },
      set: function (val) {
        store.commit('paymentPortal/SET_PAYMENT_AMOUNT', val)
      }
    },
    paymentMethod: {
      get: function () {
        return store.getters['paymentPortal/getPaymentMethod']
      },
      set: function (val) {
        store.commit('paymentPortal/SET_PAYMENT_METHOD', val)
      }
    },
    includeCashBank: {
      get: function () {
        return store.getters['paymentPortal/getIncludeCashBank']
      },
      set: function (val) {
        store.commit('paymentPortal/SET_INCLUDE_CASH_BANK_STATUS', val)
      }
    },
    isMakePaymentEnabled() {
      if (this.selectedApplication?.id && this.paymentAmount && this.paymentMethod && this.chargesList.length > 0 && !this.isAmountMoreThenTotal) {
        if (this.paymentMethod === 'Credit Card' && !this.selectedCard) {
          return false
        }
        return true
      }
      return false
    },
    totalApplicationAmount() {
      return this.chargesList.reduce((acc, curItem) => acc + Number(curItem.unpaid_sum), 0)
    },
    isAmountMoreThenTotal() {
      return this.paymentAmount > this.totalApplicationAmount
    },
  },
  methods: {
    async setModalData() {
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      await store.dispatch('paymentPortal/fetchCreditCardsList')
      await store.dispatch('paymentPortal/fetchApplications', { without_archived: false })
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
    },
    resetModal() {
      store.commit('paymentPortal/SET_PAYMENT_METHOD', 'Credit Card')
      store.commit('paymentPortal/SET_PAYMENT_AMOUNT', 0)
      store.commit('paymentPortal/SET_SLECTED_APPLICATION', null)
      store.commit('paymentPortal/SET_CHARGES_LIST', [])
      store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', '')
      store.commit('paymentPortal/SET_INCLUDE_CASH_BANK_STATUS', false)
      this.showPaymentDetails = false
    },
    selectApp(application) {
      store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', application.card_id)
      this.updateChargesList(application.id)
    },
    async updateChargesList(applicationId) {
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      const queryParams = {
        applicationId,
        is_without_charge: 0,
      }
      await store.dispatch('paymentPortal/fetchCharges', queryParams)
      this.setPaymentAmount()
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
    },
    setPaymentAmount() {
      const amount = this.chargesList.reduce((acc, curItem) => acc + Number(curItem.unpaid_sum), 0)
      store.commit('paymentPortal/SET_PAYMENT_AMOUNT', amount)
    },
    async handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      if(this.includeCashBank) {
        await this.createTransactionIncludeCashBank()
      } else {
        await this.createTransaction()
      }
    },
    async createTransaction() {
      this.submitButtonIsLoading = true
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      
      const queryParams = {
        user_id: this.userId,
        application_id: this.selectedApplication?.id,
        amount: this.paymentAmount,
        type: this.paymentMethod,
        charge_ids: this.chargesList.map(charge => charge.charge_id),
      }

      if (this.paymentMethod === 'Credit Card') {
        queryParams.card_id = this.selectedCard
      }

      await store.dispatch('paymentPortal/sendNewTransaction', queryParams)
        .then(() => {
          this.submitButtonIsLoading = false
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

          const data = {
            title: 'Make payment',
            status: 'Success',
            header: 'Your payment has been sent',
            message: '',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('make-payment')
          this.$bvModal.show('status-modal')

          store.dispatch('paymentPortal/fetchTransactionsList')
          store.dispatch('paymentPortal/fetchUpcomingPayments')
          store.dispatch('paymentPortal/fetchCashBankBalance')
        })
        .catch(() => {
          this.submitButtonIsLoading = false
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

          const data = {
            title: 'Make a payment',
            status: 'Failed',
            header: 'Your payment failed',
            message: 'You need to check if there is money on the card (or contact the bank about the error)',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('make-payment')
          this.$bvModal.show('status-modal')
        })
    },
    async createTransactionIncludeCashBank() {
      this.submitButtonIsLoading = true
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      
      const mainPaymentQueryParams = {
        user_id: this.userId,
        application_id: this.selectedApplication?.id,
        amount: this.paymentAmount - this.cashBankBalance,
        type: this.paymentMethod,
        charge_ids: this.chargesList.map(charge => charge.charge_id),
      }
      if (this.paymentMethod === 'Credit Card') {
        mainPaymentQueryParams.card_id = this.selectedCard
      }

      const cashBankPaymentQueryParams = {
        user_id: this.userId,
        application_id: this.selectedApplication?.id,
        amount: this.cashBankBalance,
        type: 'Cash Bank',
        charge_ids: this.chargesList.map(charge => charge.charge_id),
      }

      try {
        await store.dispatch('paymentPortal/sendNewTransaction', mainPaymentQueryParams)
          .then(() => {
            store.dispatch('paymentPortal/sendNewTransaction', cashBankPaymentQueryParams)
          })

        this.submitButtonIsLoading = false
        store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

        const data = {
          title: 'Make payment',
          status: 'Success',
          header: 'Your payment has been sent',
          message: '',
        }

        this.setStatusModalData(data)
        this.$bvModal.hide('make-payment')
        this.$bvModal.show('status-modal')

        store.dispatch('paymentPortal/fetchTransactionsList')
        store.dispatch('paymentPortal/fetchUpcomingPayments')
        store.dispatch('paymentPortal/fetchCashBankBalance')
      } catch (e) {
        this.submitButtonIsLoading = false
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

          const data = {
            title: 'Make a payment',
            status: 'Failed',
            header: 'Your payment failed',
            message: 'The CC was not charged, try again later or use another credit card ',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('make-payment')
          this.$bvModal.show('status-modal')
      }
    },
    isOptionSelectable(option){
      return option == 'Credit Card' || this.paymentAmount <= this.cashBankBalance
    }
  },
}
</script>
<style lang="scss">
.charge {
  .custom-control-label {
    width: 100%;
    padding-right: 20px;
  }
}
</style>