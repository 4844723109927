<template>
  <b-modal
    id="add-cash"
    ref="add-cash"
    body-class="p-10"
    centered
    title="Add Cash"
    @show="setModalData"
    @hide="resetModal"
    @ok="addCash"
  >
    <b-overlay
      id="overlay-add-cash"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <label
        class="form__row"
      >
        <span class="form__row-label">Amount Cash ($)</span>
        <input
          v-model.number="amount"
          type="number"
          class="input"
        />
      </label>
      <b-form-group label="Select Credit Card">
        <CreditCardRadioBlock 
          v-for="card in cardsList"
          :key="card.id"
          :cards-list="cardsList"
          :card="card"
          :show-delete-card="false"
        />
        <b-button
          variant="primary"
          class="w-100 mt-2"
          v-b-modal.add-new-card
        >
          Add New Credit Card +
        </b-button>
      </b-form-group>
    </b-overlay>
    <template #modal-footer="{ cancel, ok }">
      <div class="w-100 d-flex justify-content-between">
        <b-button
          class="border border-primary"
          variant="outline-primary" 
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="isOkButtonDisabled"
          @click="ok()"
        >
          Add payment
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import { BModal, BButton, BOverlay, BFormGroup } from 'bootstrap-vue'
import CreditCardRadioBlock from '@/components/site/CreditCardRadioBlock.vue'
import { setStatusModalData } from '@core/mixins/setStatusModalData'

export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    BFormGroup,

    CreditCardRadioBlock,
  },
  
  mixins: [ setStatusModalData ],
  data() {
    return {
      userId: getUserData().id,
      amount: 0,
    }
  },
  computed: {
    ...mapGetters({
      cardsList: 'paymentPortal/getCreditCardsList',
      selectedCard: 'paymentPortal/getSelectedCreditCard',
      isLoading: 'paymentPortal/getIsModalLoaidng',
    }),
    isOkButtonDisabled() {
      return this.amount <= 0 || !this.selectedCard
    }
  },
  methods: {
    async setModalData() {
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      await store.dispatch('paymentPortal/fetchCreditCardsList')
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
    },
    resetModal() {
      store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', '')
      this.amount = 0
    },
    async addCash(bvModalEvt) {
      bvModalEvt.preventDefault()

      const queryParams = {
        user_id: this.userId,
        amount: this.amount,
        card_id: this.selectedCard,
        is_cashbank: 1,
      }
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      await store.dispatch('paymentPortal/sendNewTransaction', queryParams)
        .then(() => {
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

          const data = {
            title: 'Add Cash',
            status: 'Success',
            header: 'Successfully added to Cash Bank',
            message: '',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('add-cash')
          this.$bvModal.show('status-modal')

          store.dispatch('paymentPortal/fetchCashBankBalance')
        })
        .catch(() => {
          this.submitButtonIsLoading = false
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

          const data = {
            title: 'Add Cash',
            status: 'Failed',
            header: 'Your payment failed',
            message: '',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('add-cash')
          this.$bvModal.show('status-modal')
          store.dispatch('paymentPortal/fetchCashBankBalance')
        })
    },
  },
}
</script>

<style>

</style>