<template>
  <b-modal
    id="add-new-card"
    ref="add-new-card"
    body-class="p-10"
    centered
    title="Adding New Credit Card"
    @ok="addNewCard"
  >
    <b-overlay
      id="overlay-new-card"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <NewCreditCard ref="credit-card"/>
    </b-overlay>
    <template #modal-footer="{ cancel, ok }">
      <div class="w-100 d-flex justify-content-between">
        <b-button
          class="border border-primary"
          variant="outline-primary" 
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import { BModal, BButton, BOverlay } from 'bootstrap-vue'
import NewCreditCard from '@/components/site/NewCreditCard.vue'

export default {
  components: {
    BModal,
    BButton,
    BOverlay,

    NewCreditCard,
  },
  data() {
    return {
      userId: getUserData().id,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'paymentPortal/getIsModalLoaidng',
    }),
  },
  methods: {
    async addNewCard(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.$refs['credit-card'].$v.$invalid){
        await this.$refs['credit-card'].$v.$touch();
        document.querySelector('.is-invalid').focus()
        return
      }
      const card = this.$refs['credit-card'].cardData

      const queryParams = {
        number: card.number,
        exp_month: card.expMonth,
        exp_year: card.expYear,
        cvc: card.cvc,
        user_id: this.userId,
      }

      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      await store.dispatch('paymentPortal/createNewCreditCard', queryParams)
      await store.dispatch('paymentPortal/fetchCreditCardsList') 
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
      this.$refs['add-new-card'].hide()
    }
  }
}
</script>

<style>

</style>