import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useGroupMembers() {
  // Use toast
  const toast = useToast()

  const refCashBankTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'created_at', label: 'Date', sortable: true, },
    { key: 'type', label: 'Type' },
    { key: 'description', label: 'Payment information' },
    { key: 'amount', label: 'Amount' },
  ]

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCashBankTable.value ? refCashBankTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refCashBankTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchCashBankTransactions = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      is_cashbank: 1,
    }

    store
      .dispatch('paymentPortal/fetchCashBankTransactions', payloadData)
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta

        callback(data)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  return {
    fetchCashBankTransactions,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    sortBy,
    totalItems,
    isSortDirDesc,
    refCashBankTable,

    refetchData,
  }
}
