import moment from 'moment'

export const filters = {
  filters: {
    shortDate(value) {
      return value ? moment(value).format('MM/DD/YYYY') : ''
    },
    longDate(value) {
      return value ? moment(value).format('MM/DD/YYYY HH:mm:SS') : ''
    },
    shortId(id) {
      return id ? id.slice(-4) : ''
    },
    fixAmount(val) {
      return val >= 0 ? `$${val.toFixed(2)}` : `-$${Math.abs(val.toFixed(2))}`
    },
  },
}

export const _ = null
