<template>
  <b-modal
    id="wallet"
    ref="wallet"
    centered
    title="Wallet"
    @show="setModalData"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-row
        class="p-2"
      >
        <b-col
          cols="12"
          class="mb-3"
        >
          <b-form-group
            label="Application selection"
          >
            <v-select
              v-model="selectedApplication"
              :options="applications"
              class="customSelect"
              placeholder="Please Select Application"
              :clearable="false"
              @input="selectApp"
            >
              <template
                #selected-option="{ program, user }"
              >
                <span>{{ user.full_name }}</span>&nbsp;-&nbsp;<span>{{ program }}</span>
              </template>

              <template
                #option="{ program, user }"
              >
                <span>{{ user.full_name }}</span> - <span>{{ program }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          v-if="walletsList.length > 0"
          cols="12"
          class="mb-3"
        >
          <b-form-group label="Select Wallet">
            <b-form-radio-group
              v-model="selectedWallet"
              id="wallets-radio-group"
              name="wallet"
              stacked
              :options="walletsList"
            />
          </b-form-group>
        </b-col>
        
        <b-col
          cols="12"
          class="mb-3"
        >
          <label
            class="form__row"
          >
            <span class="form__row-label">Enter your payment amount</span>
            <input
              v-model.number="amount"
              type="number"
              class="input"
            />
          </label>
          
        </b-col>

        <b-col
          cols="12"
          class="mb-1"
        >
          <b-form-group label="Select Credit Card">
            <CreditCardRadioBlock 
              v-for="card in cardsList"
              :key="card.id"
              :cards-list="cardsList"
              :card="card"
              :show-delete-card="false"
            />
            <b-button
              variant="primary"
              class="w-100 mt-2"
              v-b-modal.add-new-card
            >
              Add New Credit Card +
            </b-button>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mb-3"
        >
          <label
            class="form__row"
          >
            <span class="form__row-label">Memo (optional)</span>
            <textarea
              v-model="memo"
              placeholder="Input your text"
            />
          </label>
          
        </b-col>
      </b-row>

    </b-overlay>
    <template #modal-footer="{ cancel }">
      <div
        class="w-100 d-flex justify-content-between"
      >
        <b-button
          class="border border-primary"
          variant="outline-primary" 
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="submitButtonIsLoading || !isMakePaymentEnabled"
          @click="handleForm"
        >
          <span>Make Payment</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import store from '@/store'

import {
  BModal, BRow, BCol, BFormGroup, 
  BButton, BOverlay, BFormRadioGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import CreditCardRadioBlock from '@/components/site/CreditCardRadioBlock.vue'

import { filters } from '@core/mixins/filters'
import { setStatusModalData } from '@core/mixins/setStatusModalData'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BOverlay,
    BFormRadioGroup,

    vSelect,

    CreditCardRadioBlock,
  },
  mixins: [ filters, setStatusModalData ],
  data() {
    return {
      userId: getUserData().id,
      submitButtonIsLoading: false,
      walletsList: [],
      selectedWallet: null,
      amount: 0,
      memo: '',
    }
  },
  computed: {
    ...mapGetters({
      applications: 'paymentPortal/getApplications',
      cardsList: 'paymentPortal/getCreditCardsList',
      selectedCard: 'paymentPortal/getSelectedCreditCard',
      isLoading: 'paymentPortal/getIsModalLoaidng',
    }),
    selectedApplication: {
      get: function () {
        return store.getters['paymentPortal/getSelectedApplication']
      },
      set: function (val) {
        store.commit('paymentPortal/SET_SLECTED_APPLICATION', val)
      }
    },
    isMakePaymentEnabled() {
      return this.selectedApplication?.id && this.amount && this.selectedCard && this.selectedWallet
    },
  },
  methods: {
    async setModalData() {
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      if (!this.cardsList.length) {
        await store.dispatch('paymentPortal/fetchCreditCardsList')
      }
      await store.dispatch('paymentPortal/fetchApplications', { hasWallet: true })
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
    },
    resetModal() {
      this.selectedApplication = null
      this.walletsList = []
      this.selectedWallet = null
      this.amount = 0
      this.memo = ''

      store.commit('paymentPortal/SET_SLECTED_APPLICATION', null)
      store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', '')
    },
    selectApp(application) {
      store.commit('paymentPortal/SET_SELECTED_CREDIT_CARD', application.card_id)
      this.updateWalletsList(application.id)
    },
    async updateWalletsList(applicationId) {
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      const queryParams = {
        applicationId,
      }
      const response = await store.dispatch('paymentPortal/fetchWallets', queryParams)
      this.walletsList = response.data.data.map(item => ({
        text: `${item.name}${item.description ? `(${item.description})` : ''}`,
        value: item,
      }))
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)
    },
    async handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      await this.makePayment()
    },
    async makePayment() {
      this.submitButtonIsLoading = true
      store.commit('paymentPortal/SET_MODAL_LOADING_STATE', true)
      
      const queryParams = {
        user_id: this.userId,
        application_id: this.selectedApplication.id,
        wallet_id: this.selectedWallet.id,
        amount: this.amount,
        card_id: this.selectedCard,
      }

      if(this.memo) {
        queryParams.notes = this.memo
      }

      await store.dispatch('paymentPortal/sendNewTransaction', queryParams)
        .then(() => {
          this.submitButtonIsLoading = false
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

          const data = {
            title: 'Wallet',
            status: 'Success',
            header: `Congratulations! $${this.amount} were added to ${this.selectedWallet.name}`,
            message: '',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('wallet')
          this.$bvModal.show('status-modal')
        })
        .catch(() => {
          this.submitButtonIsLoading = false
          store.commit('paymentPortal/SET_MODAL_LOADING_STATE', false)

          const data = {
            title: 'Wallet',
            status: 'Failed',
            header: 'Your payment failed',
            message: 'You need to check if there is money on the card (or contact the bank about the error)',
          }

          this.setStatusModalData(data)
          this.$bvModal.hide('wallet')
          this.$bvModal.show('status-modal')
        })
    },
  },
}
</script>
