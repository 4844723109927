<template>
  <div class="payment-portal site__centered">
    <h2 class="payment-portal__title">Payment Portal</h2>
    <div class="payment-portal__body">
      <SideNav />
      
      <b-dropdown
        class="payment-portal__actions"
        toggle-class="payment-portal__actions-toggle"
        variant="link"
        no-caret
        no-flip
      >
        <template #button-content>
          <MoreHorizontal />
          <span>
            Actions
          </span>
        </template>
        <b-dropdown-item 
          class="payment-portal__actions-menuitem"
          v-b-modal.make-payment
        >
          Make a payment
        </b-dropdown-item>
        <b-dropdown-item 
          class="payment-portal__actions-menuitem"
          v-b-modal.change-default-card
        >
          Change default cards
        </b-dropdown-item>
        <b-dropdown-item 
          class="payment-portal__actions-menuitem"
          v-b-modal.cash-bank
        >
          CashBank information
        </b-dropdown-item>
        <b-dropdown-item 
          class="payment-portal__actions-menuitem"
          v-b-modal.wallet
        >
          Wallet
        </b-dropdown-item>
      </b-dropdown>

      <b-tabs
        class="payment-portal__tabs"
        active-nav-item-class="payment-portal__tab-active"
        content-class="mt-3"
      >
        <TransactionsViewTab />
        
        <UpcomingPaymentsTab />

        <PaymentContractsTab />
      </b-tabs>
    </div>
    <MakePayment />

    <ChangeDefaultCard />

    <StatusModal />
    
    <AddNewCard />

    <RemoveCard />

    <CashBank />
    
    <AddCash />

    <Wallet />
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import store from '@/store'

import { BTabs, BDropdown, BDropdownItem, VBModal, } from 'bootstrap-vue'

import SideNav from '@/components/site/SideNav.vue'
import TransactionsViewTab from '@/views/PaymentPortal/TransactionsView/TransactionsViewTab.vue'
import UpcomingPaymentsTab from '@/views/PaymentPortal/UpcomingPayments/UpcomingPaymentsTab.vue'
import PaymentContractsTab from '@/views/PaymentPortal/PaymentContracts/PaymentContractsTab.vue'

import MakePayment from "@/views/PaymentPortal/Modals/Payment/MakePayment.vue"
import ChangeDefaultCard from "@/views/PaymentPortal/Modals/ChangeDefaultCard/ChangeDefaultCard.vue"
import StatusModal from "@/views/PaymentPortal/Modals/Status/StatusModal.vue"
import CashBank from "@/views/PaymentPortal/Modals/CashBank/CashBank.vue"
import AddCash from "@/views/PaymentPortal/Modals/AddCash/AddCash.vue"
import AddNewCard from '@/views/PaymentPortal/Modals/AddNewCard/AddNewCard.vue'
import RemoveCard from '@/views/PaymentPortal/Modals/RemoveCard/RemoveCard.vue'
import Wallet from '@/views/PaymentPortal/Modals/Wallet/Wallet.vue'

import MoreHorizontal from '@/assets/images/icons/more-horizontal.svg'

export default {
  name: "PaymentPortal",
  components: {
    BTabs,
    BDropdown,
    BDropdownItem,

    SideNav,
    TransactionsViewTab,
    UpcomingPaymentsTab,
    PaymentContractsTab,
    MakePayment,
    ChangeDefaultCard,
    StatusModal,
    CashBank,
    AddCash,
    AddNewCard,
    RemoveCard,
    Wallet,

    MoreHorizontal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      user: getUserData(),
    }
  },
  async created() {
    store.commit('family/SET_FAMILY_ID', this.user.family_id)
    await store.dispatch('paymentPortal/fetchCashBankBalance')
  },
}
</script>

<style>

</style>