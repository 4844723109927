import { render, staticRenderFns } from "./RemoveCard.vue?vue&type=template&id=18697bc7&scoped=true&"
import script from "./RemoveCard.vue?vue&type=script&lang=js&"
export * from "./RemoveCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18697bc7",
  null
  
)

export default component.exports