<template>
  <b-modal
    id="status-modal"
    ref="status-modal"
    body-class="p-10"
    centered
    :title="title"
    ok-only
    ok-title="Close"
    @hide="resetModal"
  >
    <div
      class="d-flex flex-column align-items-center p-5"
    >
      <SuccessSVG
        v-if="status == 'Success'"
        class="mb-3 primary-color"
      />
      <FailedSVG
        v-if="status == 'Failed'"
        class="mb-3 primary-color"
      />
      <h5 class="text-secondary mb-3">{{ header }}</h5>
      <p class="text-center">{{ message }}</p>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import SuccessSVG from '@/assets/images/icons/success.svg'
import FailedSVG from '@/assets/images/icons/failed.svg'

export default {
  components: {
    SuccessSVG,
    FailedSVG,
  },
  computed: {
    ...mapGetters({
      title: 'paymentPortal/getStatusModalTitle',
      status: 'paymentPortal/getStatusModalStatus',
      header: 'paymentPortal/getStatusModalHeader',
      message: 'paymentPortal/getStatusModalMessage',
    }),
  },
  methods: {
    resetModal() {
      store.commit('paymentPortal/SET_STATUS_MODAL_TITLE', '')
      store.commit('paymentPortal/SET_STATUS_MODAL_STATUS', '')
      store.commit('paymentPortal/SET_STATUS_MODAL_HEADER', '')
      store.commit('paymentPortal/SET_STATUS_MODAL_MESSAGE', '')
    }
  }
}
</script>

<style lang="scss" scoped>
svg > path, svg > circle {
  fill: $primary;
}
</style>